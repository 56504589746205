import React, { useState, useEffect } from 'react';
import { useNFOutrasOperacao } from '~/pages/NFOutrasOperacoes/NFOutrasOperacoesContext';
import CustomDataGrid from '../../../../myComponents/CustomDataGrid';
import { ContainerAcoes } from './styles';

const TableAjuste: React.FC = () => {
  const { itensAjuste, checkItem, init } = useNFOutrasOperacao();

  const columns = [
    {
      field: 'marca',
      headerName: 'Marcar?',
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (row: any) => {
        return (
          <ContainerAcoes>
            <input
              type="checkbox"
              id="marcar"
              name="marcar"
              checked={row.marca}
              onChange={() => {
                checkItem(row?.id, !row.marca);
              }}
            />
          </ContainerAcoes>
        );
      },
    },
    {
      field: 'loja',
      headerName: 'Loja',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_ajuste',
      headerName: 'Cód. Ajuste',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'qtd_ajuste',
      headerName: 'Qtd Ajuste',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_produto',
      headerName: 'Cód. Produto',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_produto',
      headerName: 'Produto',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dta_ajuste',
      headerName: 'Data',
      width: 150,
      format: 'dta',
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <CustomDataGrid
      key="ajuste"
      isEmptyLineMessage={init}
      rows={itensAjuste}
      columns={columns}
      emptyLineMessage="Nenhum registro encontrado conforme os critérios informados ou já importados para a NF."
    />
  );
};

export default TableAjuste;
