import React, { useEffect, useState } from 'react';
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import CircularProgress from '@material-ui/core/CircularProgress';

import { InputSelect } from '~/pages/NFOutrasOperacoes/myComponents/InputSelect';
import { InputDate } from '~/components/NovosInputs';

import { useNFOutrasOperacao } from '../../../NFOutrasOperacoesContext';
import { GroupButton, CloseButton, ContainerButton, Label } from './styles';
import { FaEraser } from 'react-icons/fa';
import { MdOutlineCancel } from 'react-icons/md';
import TableAjuste from './TableAjuste';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';

const ModalImportarAjuste: React.FC = () => {
  const {
    isOpenModalImportAjuste,
    setIsOpenModalImportAjuste,
    registerModalAjuste,
    controlModalAjuste,
    setValueModalAjuste,
    formStateModalAjuste,
    tipo_ajustes,
    getProdutos,
    checkAllItens,
    itensAjuste,
    handleSubmitModalAjuste,
    resetModalAjuste,
    setItensAjuste,
    loadingAjuste,
    setInit,
  } = useNFOutrasOperacao();
  const [marcar, setMarcar] = useState<boolean>(false);

  useEffect(() => {
    if (itensAjuste.length > 0) {
      const itensChecked = itensAjuste.filter((item) => item.marca === true);
      if (itensChecked.length === itensAjuste.length) {
        setMarcar(true);
      } else {
        setMarcar(false);
      }
    }
  }, [itensAjuste]);

  const handleCloseModal = () => {
    setIsOpenModalImportAjuste(false);
  };
  return (
    <>
      {isOpenModalImportAjuste && (
        <Modal width="x-large" onClose={handleCloseModal}>
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>Selecionar Ajuste(s) NF</ModalTitle>
            <CloseButton onClick={handleCloseModal}>
              <AiOutlineClose size={24} />
            </CloseButton>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={8} sm={12} style={{ marginTop: '25px' }}>
                <GroupButton>
                  <label htmlFor="cfop" style={{ width: '70px' }}>
                    Tipo Ajuste
                  </label>
                  <InputSelect
                    label=""
                    placeholder="Selecione..."
                    id="tipo_ajuste"
                    name="tipo_ajuste"
                    register={registerModalAjuste}
                    control={controlModalAjuste}
                    isError={!!formStateModalAjuste.errors.tipo_ajuste}
                    options={tipo_ajustes}
                    changeSelected={(selected) =>
                      setValueModalAjuste('tipo_ajuste', selected)
                    }
                  />
                </GroupButton>
              </Col>
              <Col md={2} sm={12}>
                <InputDate
                  label="Data do Ajuste"
                  placeholder=""
                  name="dta_ini_ajuste"
                  register={registerModalAjuste}
                  control={controlModalAjuste}
                  disabled={false}
                  isError={!!formStateModalAjuste.errors.dta_ini_ajuste}
                />
              </Col>
              <Col md={2} sm={12}>
                <InputDate
                  label=""
                  placeholder=""
                  name="dta_fim_ajuste"
                  register={registerModalAjuste}
                  control={controlModalAjuste}
                  disabled={false}
                  isError={!!formStateModalAjuste.errors.dta_fim_ajuste}
                />
              </Col>
            </Row>
            <Row>
              <ContainerButton>
                <CustomButtonNovo
                  onClick={getProdutos}
                  label="Pesquisar"
                  icon={IoIosSearch}
                />
                <CustomButtonNovo
                  variant="cancel"
                  onClick={() => {
                    resetModalAjuste();
                    setItensAjuste([]);
                    setInit(false);
                  }}
                  label="Cancelar"
                  icon={MdOutlineCancel}
                />
                <CustomButtonNovo
                  variant="clear"
                  onClick={() => {
                    resetModalAjuste();
                    setValueModalAjuste('dta_ini_ajuste', '');
                    setValueModalAjuste('dta_fim_ajuste', '');
                    setItensAjuste([]);
                    setInit(false);
                  }}
                  icon={FaEraser}
                  label="Limpar"
                />
              </ContainerButton>
            </Row>
            <Row>
              <Label htmlFor="marcar">
                <input
                  type="checkbox"
                  id="marcar"
                  name="marcar"
                  checked={marcar}
                  onChange={() => {
                    setMarcar(!marcar);
                    checkAllItens(!marcar);
                  }}
                />
                Marcar todos
              </Label>
            </Row>
            <Row>
              <TableAjuste />
            </Row>
          </ModalBody>
          <ModalFooter
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
          >
            <Button
              variant="danger"
              onClick={() => setIsOpenModalImportAjuste(false)}
            >
              Cancelar
            </Button>
            <Button variant="success" onClick={() => handleSubmitModalAjuste()}>
              {loadingAjuste ? (
                <CircularProgress size={15} style={{ color: '#ffffff' }} />
              ) : (
                'Adicionar'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ModalImportarAjuste;
