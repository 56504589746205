import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  InputNumber,
  InputMoney,
  InputText,
  InputTextArea,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useNFOutrasOperacao } from '../../NFOutrasOperacoesContext';
import { ContainerScreens } from './styles';

export const Transporte: React.FC = () => {
  const {
    registerTransporte,
    formStateTransporte,
    controlTransporte,
    disable,
  } = useNFOutrasOperacao();

  return (
    <ContainerScreens>
      <Row>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputMoney
            label="Seguro"
            min={0}
            step={1}
            decimals={999999}
            placeholder="0,00"
            name="val_seguro"
            disabled={disable}
            register={registerTransporte}
            isError={!!formStateTransporte.errors.val_seguro}
            showIcon={false}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputMoney
            label="Qtd. Emb"
            min={0}
            step={1}
            decimals={999999}
            placeholder="0"
            disabled={disable}
            name="qtd_embalagem"
            register={registerTransporte}
            isError={!!formStateTransporte.errors.qtd_embalagem}
            showIcon={false}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputMoney
            label="Peso Líq."
            min={0}
            step={1}
            decimals={999999}
            placeholder="0,00"
            disabled={disable}
            name="qtd_peso_liq"
            register={registerTransporte}
            isError={!!formStateTransporte.errors.qtd_peso_liq}
            showIcon={false}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputMoney
            label="Peso Bruto."
            min={0}
            step={1}
            decimals={999999}
            placeholder="0,00"
            disabled={disable}
            name="qtd_peso_bruto"
            register={registerTransporte}
            isError={!!formStateTransporte.errors.qtd_peso_bruto}
            showIcon={false}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputText
            label="Marca Veic."
            placeholder=""
            name="des_marca_veiculo"
            maxLength={25}
            disabled={disable}
            register={registerTransporte}
            isError={!!formStateTransporte.errors.des_marca_veiculo}
          />
        </Col>
        <Col md={2} sm={12} style={{ marginTop: '10px' }}>
          <InputText
            label="Placa Veic."
            placeholder=""
            name="des_placa_veiculo"
            maxLength={25}
            disabled={disable}
            register={registerTransporte}
            isError={!!formStateTransporte.errors.des_placa_veiculo}
          />
        </Col>
      </Row>
      <Separator labelText="Observação da Nota Fiscal" sidePadding="0 0" />
      <Row>
        <InputTextArea
          name="des_obs_fiscal"
          disabled={disable}
          register={registerTransporte}
          isError={!!formStateTransporte.errors.des_obs_fiscal}
          maxLength={2000}
          control={controlTransporte}
        />
      </Row>
    </ContainerScreens>
  );
};
