import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;

export const GroupButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  white-space: nowrap;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8571428571428571em;
  font-style: inherit;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-weight: 600;

  #ncm {
    margin-top: 6px;
    div {
      background-color: var(
        --ds-background-subtleBorderedNeutral-resting,
        #fafbfc
      );
      border-color: var(--ds-border-neutral, #dfe1e6);
    }
  }
`;

export const ToogleContainer = styled.div`
  height: 50px;
  label[data-checked] {
    background-color: #8850bf !important;
    color: #ffffff;
  }
`;
