import styled from 'styled-components';

export const ContainerScreens = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
`;

export const GroupButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  white-space: nowrap;
  margin: auto;
  gap: 10px;
  align-items: center;
  font-size: 0.8571428571428571em;
  font-style: inherit;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-weight: 600;
`;
