export const tableItensColumns = [
  {
    field: 'num_item',
    headerName: 'Item',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'cod_produto',
    headerName: 'Código PLU',
    width: 140,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'des_produto',
    headerName: 'Descrição',
    width: 400,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'cfop',
    headerName: 'CFOP',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'cod_id_ctb',
    headerName: 'ID Contábi',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'num_ncm',
    headerName: 'NCM',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'qtd_embalagem',
    headerName: 'Embalagem',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'qtd_entrada',
    headerName: 'Qtd. Entrada',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'qtd_total',
    headerName: 'Qtd. Total',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_unitario',
    headerName: 'Valor Tabela',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_total',
    headerName: 'Valor Total',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_desconto',
    headerName: 'Tot. Desconto',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_despesa_acessoria',
    headerName: 'Tot. Acrésc.',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_tabela_liq',
    headerName: 'Valor Líquido',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_ipi',
    headerName: 'Valor IPI',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_tabela_final',
    headerName: 'Valor Liq c/ IPI',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_bc_icms',
    headerName: 'BC ICMS Op',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_icms',
    headerName: 'Crédito ICMS Op',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_outros',
    headerName: 'Outros ICMS',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_bc_st',
    headerName: 'BC ICMS ST',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_icms_st',
    headerName: 'Val Tot ST',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'tipo_tributacao',
    headerName: 'Tipo da tributação',
    options: [
      { value: 0, label: 'Tributado' },
      { value: 1, label: 'Isento' },
      { value: 2, label: 'Redução' },
      { value: 3, label: 'Substituição' },
      { value: 4, label: 'Suspensão' },
      { value: 5, label: 'Diferido' },
      { value: 6, label: 'Outros ICMS' },
      { value: 7, label: 'Portaria CAT-38' },
      { value: 8, label: 'Não Tributado' },
    ],
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'per_icms',
    headerName: 'Perc. ICMS',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_reducao',
    headerName: 'Red. BC.',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'val_frete',
    headerName: 'Val Tot Frete',
    width: 150,
    format: 'number',
    sortable: false,
    disableColumnMenu: true,
  },
];
