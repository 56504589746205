import styled from 'styled-components';

export const ContainerAcoes = styled.div`
  width: 80;
  height: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
`;
export const ContainerTotais = styled.div`
  width: 100%;
  height: 40px;
  background: #daebf6;
  display: grid;
  font-weight: 600;
  grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr;
  gap: 5px;
  padding: 10px;
  align-items: center;
`;
