export interface LojaProps {
  cod_loja: number | undefined;
  des_loja: string;
  label: string;
  selected: boolean;
  uf: string;
  value: number | undefined;
  num_cnpj: string;
}
export const lojaDefault = {
  cod_loja: undefined,
  des_loja: '',
  label: '',
  selected: false,
  uf: '',
  value: undefined,
  num_cnpj: '',
};
