import styled from 'styled-components';

export const ContainerTable = styled.div`
  min-height: 250px;
  @media (max-width: 1200px) {
    margin-bottom: 50px;
  }
  @media (max-width: 1000px) {
    margin-bottom: 100px;
  }
  @media (max-width: 600px) {
    margin-bottom: 200px;
  }
`;
export const ContainerAcoes = styled.div`
  width: 100px;
  height: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  transition: all 0.5s;
  button:disabled {
    cursor: not-allowed;
  }
  button {
    background: none;
    border: none;
    svg {
      font-size: 20pt;
    }
  }
`;
export const ContainerTotais = styled.div`
  width: 100%;
  position: relative;
  min-height: 40px;
  background: #daebf6;
  font-weight: 600;
  padding: 10px;
  display: flex;
  align-items: center;
  @media (min-width: 1000px) {
    white-space: nowrap;
  }
  .resumo-coluan {
    display: flex;
    width: auto;
  }
  #linha-item-total {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .coluna-item-total {
    width: auto;
    padding: 5px;
    margin: 0;
  }
`;
