import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNFOutrasOperacao } from '~/pages/NFOutrasOperacoes/NFOutrasOperacoesContext';
import CustomDataGrid from '../../../myComponents/CustomDataGrid';
import { ContainerTable, ContainerAcoes, ContainerTotais } from './styles';
import { MdEdit } from 'react-icons/md';
import { BsTrashFill } from 'react-icons/bs';
import { ScrollButtons } from './ScrollButtons';
import { tableItensColumns } from '../tableItensColumns';
import { procedureSubtotais } from '~/pages/NFOutrasOperacoes/functions/procedures/procedureSubtotais';

const TableItens: React.FC = () => {
  const { total, setTotal, loading, itens, updateItem, deleteItem } =
    useNFOutrasOperacao();

  useEffect(() => {
    const result = procedureSubtotais(itens);
    setTotal(result);
  }, [itens]);

  const columns = [
    ...tableItensColumns,
    {
      field: 'acoes',
      headerName: 'Ações',
      fixed: true,
      fixedDirection: 'right',
      width: 100,
      renderCell: (row: any) => {
        return (
          <ContainerAcoes>
            <button
              type="button"
              disabled={
                row.isDisabledEdit || row.isDisabledNF ? true : row.isDisabled
              }
              onClick={() => updateItem(row.val_inputs, row.num_item)}
            >
              <MdEdit color="#FE9901" />
            </button>
            <button
              disabled={row.isDisabledNF ? true : row.isDisabled}
              type="button"
              onClick={() => deleteItem(row)}
            >
              <BsTrashFill color="#CF2C2D" />
            </button>
          </ContainerAcoes>
        );
      },
    },
  ];

  return (
    <ContainerTable>
      <ScrollButtons />
      <CustomDataGrid
        key="nf-itens"
        loading={loading}
        tooltip={[
          { label: 'Seq:', keyColum: 'num_item', separator: ' - ' },
          { label: '', keyColum: 'des_produto', separator: ' - ' },
          {
            label: 'Código do Produto:',
            keyColum: 'cod_produto',
            separator: '',
          },
        ]}
        rows={itens}
        columns={columns}
      />
      <ContainerTotais>
        <Row id="linha-item-total">
          <div className="resumo-coluan">
            <div className="coluna-item-total">
              <span>Total Itens:</span>
            </div>
            <div className="coluna-item-total">
              <span className="valores">
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(total.Total_Tab)}
              </span>
            </div>
          </div>

          <div className="resumo-coluan">
            <div className="coluna-item-total">
              <span>Total IPI:</span>
            </div>
            <div className="coluna-item-total">
              <span className="valores">
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(total.Total_IPI)}
              </span>
            </div>
          </div>
          <div className="resumo-coluan">
            <div className="coluna-item-total">
              <span>Valor ICMS:</span>
            </div>
            <div className="coluna-item-total">
              <span className="valores">
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(total.Credito_ICMS)}
              </span>
            </div>
          </div>
          <div className="resumo-coluan">
            <div className="coluna-item-total">
              <span>Total NF:</span>
            </div>
            <div className="coluna-item-total">
              <span className="valores">
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(total.Total_Nota_Fiscal)}
              </span>
            </div>
          </div>
        </Row>
      </ContainerTotais>
    </ContainerTable>
  );
};

export default TableItens;
