import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaEraser, FaSync } from 'react-icons/fa';
import { InputMoney, InputDate, InputSelect } from '~/components/NovosInputs';
import TableFinanceiro from './TableFinanceiro';
import { useNFOutrasOperacao } from '../../NFOutrasOperacoesContext';
import Separator from '~/components/Separator';
// eslint-disable-next-line import/named
import { ContainerScreens, ButtonItens, ContainerTotal } from './styles';
import getFinalizadoras from '../../services/getFinalizadoras';
import getCondicoes from '../../services/getCondicoes';
import { formatCurrencyAsText } from '~/utils/functions';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdModeEdit } from 'react-icons/md';

export const Finaceiro: React.FC = () => {
  const {
    formStateFinanceiro,
    controlFinanceiro,
    registerFinanceiro,
    setValueFinanceiro,
    setFinalizadoras,
    finalizadoras,
    setCondicoes,
    addItemFinaceiro,
    onClearFinaceiro,
    updateFinaceiro,
    financeiroCalcular,
    itensFinaceiro,
    disable,
    total,
  } = useNFOutrasOperacao();

  const arredondarParaDuasCasas = useCallback((valor: number): number => {
    const [, decimais] = valor.toString().split('.');

    if (decimais && decimais.length > 2) return Math.round(valor * 100) / 100;
    return valor;
  }, []);

  useEffect(() => {
    (async () => {
      const optionsFinalizadora = await getFinalizadoras();
      setFinalizadoras(optionsFinalizadora);

      const optionsCondicao = await getCondicoes();
      setCondicoes(optionsCondicao);
    })();
  }, []);

  useEffect(() => {
    if (itensFinaceiro.length !== 0) {
      const totalParcelas = itensFinaceiro.reduce(
        (acc, parcela) => acc + parcela.val_parcela,
        0,
      );
      const resultado = total.Total_Nota_Fiscal - totalParcelas;

      setTimeout(() => {
        setValueFinanceiro(
          'val_financeiro',
          formatCurrencyAsText(
            arredondarParaDuasCasas(resultado > 0 ? resultado : 0),
          ),
        );
      }, 20);
    } else {
      setValueFinanceiro(
        'val_financeiro',
        formatCurrencyAsText(arredondarParaDuasCasas(total.Total_Nota_Fiscal)),
      );
    }
  }, [itensFinaceiro, total.Total_Nota_Fiscal, arredondarParaDuasCasas]);

  return (
    <ContainerScreens>
      <Row>
        <Col md={10} sm={12}>
          <Separator labelText="Financeiro" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={12}>
          <ButtonItens
            disabled={disable}
            type="button"
            onClick={() => financeiroCalcular()}
          >
            <FaSync color="#28a745" />
            <span>(Re) Calcular</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row style={{ minHeight: '70px', alignItems: 'flex-end' }}>
        <Col md={4} sm={12}>
          <InputSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="cod_finalizadora"
            disabled={disable}
            register={registerFinanceiro}
            options={finalizadoras}
            isError={!!formStateFinanceiro.errors.cod_finalizadora}
            control={controlFinanceiro}
            changeSelected={(selected: any) => {
              setValueFinanceiro('cod_finalizadora', selected);
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            register={registerFinanceiro}
            label="Vencimento"
            name="dta_vencimento"
            control={controlFinanceiro}
            disabled={disable}
            isError={!!formStateFinanceiro.errors.dta_vencimento}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_financeiro"
            disabled={disable}
            register={registerFinanceiro}
            isError={!!formStateFinanceiro.errors.val_financeiro}
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={addItemFinaceiro}
            label={updateFinaceiro ? 'Editar' : 'Adicionar'}
            icon={updateFinaceiro ? MdModeEdit : IoMdAddCircleOutline}
            disabled={disable}
            variant="confirm"
            width="100%"
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={onClearFinaceiro}
            label="Limpar"
            icon={FaEraser}
            disabled={disable}
            variant="clear"
            width="100%"
          />
        </Col>
      </Row>
      <br />
      <Row>
        <TableFinanceiro />
      </Row>
      <Row>
        <ContainerTotal>
          <span>Total Financeiro:</span>
          <span>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(
              itensFinaceiro.reduce(
                (sum, { val_parcela }) => sum + val_parcela,
                0,
              ),
            )}
          </span>
        </ContainerTotal>
      </Row>
    </ContainerScreens>
  );
};
