import styled from 'styled-components';

export const ContainerAcoes = styled.div`
  width: 100px;
  height: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  transition: all 0.5s;
  button {
    background: none;
    border: none;
    svg {
      font-size: 20pt;
    }
  }
`;
