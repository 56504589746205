import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;

export const GroupButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  white-space: nowrap;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8571428571428571em;
  font-style: inherit;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-weight: 600;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
  button {
    display: flex;
    font-size: 12;
    color: #fff;
    gap: 5px;
    align-items: center;
  }
`;

export const Label = styled.label`
  width: 200px;
  height: 40px;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 0.8571428571428571em;
  font-style: inherit;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-weight: 600;
`;
