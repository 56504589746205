import React from 'react';
import { Button, ButtonProps, Row } from 'react-bootstrap';

// import { GridColumns, GridColDef } from '@material-ui/data-grid';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

// import { tableItensColumns } from '~/pages/EntradaNFE/protocols';
import { tableItensColumns } from '../../tableItensColumns';

import { ScrollButtonsContainer } from './style';

interface ScrollButtonProps extends ButtonProps {
  field: string;
}

const ScrollButton = ({ onClick, field, ...rest }: ScrollButtonProps) => {
  return <Button {...rest} onClick={() => scroll(field)} />;
};

const scroll = (field: string) => {
  const index = tableItensColumns.findIndex(
    (column: CustomGridColumns) => column.field === field,
  );
  const columns = tableItensColumns.slice(0, index);
  let offset = 0;
  columns.map((column: CustomGridColumns) => {
    offset += column.width || 0;
    return column;
  });
  if (offset === 0) {
    document.getElementsByClassName(
      'tableNFOutrasOperacoesContainer',
    )[0].scrollLeft = 0;
    return;
  }
  const tableElement = document.querySelector<HTMLElement>(
    '#tableNFOutrasOperacoes',
  );
  const tableWidth = tableElement?.offsetWidth || 0;
  if (offset >= tableWidth) {
    document.getElementsByClassName(
      'tableNFOutrasOperacoesContainer',
    )[0].scrollLeft = tableWidth;
    return;
  }
  document.getElementsByClassName(
    'tableNFOutrasOperacoesContainer',
  )[0].scrollLeft = offset;
};

export const ScrollButtons: React.FC = () => {
  return (
    <>
      <ScrollButtonsContainer>
        <ScrollButton field="num_item">Identificador</ScrollButton>
        <ScrollButton field="qtd_embalagem">Quantidade</ScrollButton>
        <ScrollButton field="val_desconto">Variáveis</ScrollButton>
        <ScrollButton field="val_bc_icms">ICMS</ScrollButton>
        <ScrollButton field="val_bc_st">ST</ScrollButton>
        <ScrollButton field="per_icms">Tributação</ScrollButton>
        <ScrollButton field="val_frete">Outros</ScrollButton>
      </ScrollButtonsContainer>
    </>
  );
};
