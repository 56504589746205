import React, { useState } from 'react';
import CustomDataGrid from '../../../myComponents/CustomDataGrid';
import { useNFOutrasOperacao } from '~/pages/NFOutrasOperacoes/NFOutrasOperacoesContext';

export const TableLog: React.FC = () => {
  const { logs } = useNFOutrasOperacao();
  const columns = [
    {
      field: 'dta_cadastro',
      headerName: 'Data Envio',
      width: 200,
      format: 'dtatime',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_tipo_log',
      headerName: 'Status',
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'mensagem',
      headerName: 'Mensagem',
      width: 300,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'recibo',
      headerName: 'Recibo',
      flex: 1,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'protocolo',
      headerName: 'Protocolo',
      flex: 1,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
  ];
  return <CustomDataGrid key="log" rows={logs} columns={columns} />;
};
