import styled from 'styled-components';

export const ScrollButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 0.25rem;
  gap: 0.25rem;
  flex-wrap: wrap;

  button {
    background-color: #57069e;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-family: Jost, sans-serif;
    &:active,
    &:focus,
    &:hover {
      background-color: #8850bf;
      outline: none;
      box-shadow: none;
    }
  }
`;
